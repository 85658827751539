import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';

import media from 'styles/media';
import { Row, Col } from 'styles/grid';
import { BlockTitle, BiggerText } from 'components/texts';
import NonStretchedImage from './non-stretched-image';

const BlockRow = styled(Row)`
  margin-top: 100px;
  ${media.md`
    margin-top: 120px;
  `}
`;

const BlockContent = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const BlockImageCol = styled(Col)`
  text-align: ${props => (props.isLeft ? 'left' : 'right')};
  padding: 0;
  margin-top: 20px;
  ${media.md`
    margin-top: 0;
  `}
`;

const Block = ({ subtitle, titleText, imageFluid, isImageLeft, children, alt, link }) => (
  <BlockRow align="center" justify="space-between">
    <BlockContent sm={12} md={5} orderSm={isImageLeft ? 1 : null} orderMd={isImageLeft ? 2 : null}>
      {subtitle && (
        <BiggerText weight="bold" color="fitle">
          {subtitle}
        </BiggerText>
      )}
      {link ? (
        <Link to={link} style={{ textDecoration: 'none' }}>
          <BlockTitle>{titleText}</BlockTitle>
        </Link>
      ) : (
        <BlockTitle>{titleText}</BlockTitle>
      )}
      {children}
    </BlockContent>
    <BlockImageCol sm={12} md={6} orderSm={isImageLeft ? 2 : null} orderMd={isImageLeft ? 1 : null}>
      {link ? (
        <Link to={link} style={{ textDecoration: 'none' }}>
          <NonStretchedImage
            fluid={imageFluid}
            style={{ maxHeight: '100%', objectFit: 'contain' }}
            alt={alt}
          />
        </Link>
      ) : (
        <NonStretchedImage
          fluid={imageFluid}
          style={{ maxHeight: '100%', objectFit: 'contain' }}
          alt={alt}
        />
      )}
    </BlockImageCol>
  </BlockRow>
);

Block.defaultProps = {
  isImageLeft: false,
};

Block.propTypes = {
  subtitle: PropTypes.string,
  titleText: PropTypes.string.isRequired,
  imageFluid: PropTypes.object.isRequired,
  isImageLeft: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Block;
